.spninstrusction {
  font-size: larger;
  font-weight: 900;
  cursor: pointer;
  float: right;
  padding-top: 4px;
}
.spnHeader{
    font-weight:bold;
    font-size:large
}
.spnTitle {
  font-weight:bold;
}
.ulItems {
  list-style-type: square;
}
.dvBreak{
    padding-top:12px;
}
.line {
  width: 96%;
  height: 4px;
  border-bottom: 1px solid #ccc;
  position: absolute;
 
}
